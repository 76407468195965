/**
 *  Pages Authentication
 */
'use strict';

document.addEventListener('DOMContentLoaded', function () {
  (() => {
    const formAuthentication = document.querySelector('#formAuthentication');

    // Form validation for Add new record
    if (formAuthentication && typeof FormValidation !== 'undefined') {
      FormValidation.formValidation(formAuthentication, {
        fields: {
          username: {
            validators: {
              notEmpty: {
                message: 'Por favor ingresa tu nombre de usuario'
              },
              stringLength: {
                min: 6,
                message: 'El nombre de usuario debe tener minimo 6 carácteres'
              }
            }
          },
          email: {
            validators: {
              notEmpty: {
                message: 'Por favor ingresa tu correo electrónico'
              },
              emailAddress: {
                message: 'Por favor ingresa un correo electrónico válido'
              }
            }
          },
          'email-username': {
            validators: {
              notEmpty: {
                message: 'Por favor ingresa tu correo electrónico o tu nombre de usuario'
              },
              stringLength: {
                min: 6,
                message: 'El nombre de usuario debe tener minimo 6 carácteres'
              }
            }
          },
          password: {
            validators: {
              notEmpty: {
                message: 'Por favor ingresa tu contraseña'
              },
              stringLength: {
                min: 6,
                message: 'La contraseña debe contener mínimo 6 carácteres'
              }
            }
          },
          'confirm-password': {
            validators: {
              notEmpty: {
                message: 'Confirmar contraseña'
              },
              identical: {
                compare: () => formAuthentication.querySelector('[name="password"]').value,
                message: 'Las contraseñas no coinciden'
              },
              stringLength: {
                min: 6,
                message: 'La contraseña debe contener mínimo 6 carácteres'
              }
            }
          },
          terms: {
            validators: {
              notEmpty: {
                message: 'Por favor acepta los términos y condiciones'
              }
            }
          }
        },
        plugins: {
          trigger: new FormValidation.plugins.Trigger(),
          bootstrap5: new FormValidation.plugins.Bootstrap5({
            eleValidClass: '',
            rowSelector: '.form-control-validation'
          }),
          submitButton: new FormValidation.plugins.SubmitButton(),
          defaultSubmit: new FormValidation.plugins.DefaultSubmit(),
          autoFocus: new FormValidation.plugins.AutoFocus()
        },
        init: instance => {
          instance.on('plugins.message.placed', e => {
            if (e.element.parentElement.classList.contains('input-group')) {
              e.element.parentElement.insertAdjacentElement('afterend', e.messageElement);
            }
          });
        }
      });
    }

    // Two Steps Verification for numeral input mask
    const numeralMaskElements = document.querySelectorAll('.numeral-mask');

    // Format function for numeral mask
    const formatNumeral = value => value.replace(/\D/g, ''); // Only keep digits

    if (numeralMaskElements.length > 0) {
      numeralMaskElements.forEach(numeralMaskEl => {
        numeralMaskEl.addEventListener('input', event => {
          numeralMaskEl.value = formatNumeral(event.target.value);
        });
      });
    }
  })();
});
